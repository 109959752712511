<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3">
          <h1 class="title-section mb-5" data-aos="fade-left" v-html="$t('retail.lead')"></h1>
          <p class="text-paragraph" v-html="$t('retail.textlead')"></p>
        </div>
        <div class="col-lg-6 mb-3">
          <img class="card-img-top" src="/img/ai.jpeg" alt="Card image cap">
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4" v-html="$t('digital.leadai')"></h4>
          <TextScroller :class="'digital1'" :text="$t('digital.textai')"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="card mb-4">
            <div class="card-body">
              <h1 class="mb-5" data-aos="fade-down">1.E-Commerce</h1>
              <p class="text-paragraph" v-html="$t('retail.text1')"></p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card mb-4">
            <div class="card-body">
              <h1 class="mb-5" data-aos="fade-down">2.App</h1>
              <p class="text-paragraph" v-html="$t('retail.text2')"></p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card mb-4">
            <div class="card-body">
              <h1 class="mb-5" data-aos="fade-down">3.POS</h1>
              <p class="text-paragraph" v-html="$t('retail.text3')"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center">
          <p class="text-paragraph mt-4">LA SOLUZIONE AI TUOI PROBLEMI E'</p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-12">
          <div class="cardblock bg-black text-white">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="title-section" data-aos="fade-down">Saturno Ret[AI]l</h1>
                <h4>La vendita della prossima generazione</h4>
                <p class="text-paragraph" v-html="$t('retail.text2')"></p>
              </div>
            </div>
            <div class="row mt-5 mb-5">
              <div class="col-lg-4">
                <h1 class="title-data" data-aos="fade-right">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.602 3.7c-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 3.321 0 5.97 2.117 5.97 6.167 0 3.555-1.949 6.833-2.383 7.833h-2.115c.392-1.536 2.499-4.366 2.499-7.842 0-5.153-5.867-4.985-7.369-2.458zm13.398 15.3h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z"/></svg>
                </h1>
              </div>
              <div class="col-lg-8">
                <h2 class="title text-white" data-aos="fade-left">{{$t('home.title1')}}</h2>
                <p class="text-paragraph" v-html="$t('home.text1')"></p>
              </div>
            </div>
            <div class="row mt-5 mb-5">
              <div class="col-lg-4">
                <h1 class="title-data" data-aos="fade-right">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.428 6c6.018 0 8.414 10 13.143 10 2.114 0 3.48-1.578 3.48-4s-1.366-4-3.48-4c-1.665 0-2.861 1.069-4.017 2.395l-1.244-1.562c1.499-1.532 3.049-2.833 5.26-2.833 3.198 0 5.43 2.455 5.43 6s-2.232 6-5.43 6c-6.003 0-8.406-10-13.142-10-2.113 0-3.479 1.578-3.479 4s1.365 4 3.479 4c1.664 0 2.86-1.068 4.015-2.392l1.244 1.561c-1.499 1.531-3.05 2.831-5.259 2.831-3.197 0-5.428-2.455-5.428-6s2.231-6 5.428-6z"/></svg>
                </h1>
              </div>
              <div class="col-lg-8">
                <h2 class="title text-white" data-aos="fade-left">{{$t('home.title2')}}</h2>
                <p class="text-paragraph" v-html="$t('home.text2')"></p>
              </div>
            </div>
            <div class="row mt-5 mb-5">
              <div class="col-lg-4">
                <h1 class="title-data" data-aos="fade-right">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 7h-4v-3c0-2.209-1.791-4-4-4s-4 1.791-4 4v3h-4l-2 17h20l-2-17zm-11-3c0-1.654 1.346-3 3-3s3 1.346 3 3v3h-6v-3zm-4.751 18l1.529-13h2.222v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h6v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h2.222l1.529 13h-15.502z"/></svg>
                </h1>
              </div>
              <div class="col-lg-8">
                <h2 class="title text-white" data-aos="fade-left">{{$t('home.title3')}}</h2>
                <p class="text-paragraph" v-html="$t('home.text3')"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">
            I settori<br>in cui operiamo
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 mb-4">
          <div class="card bg-black text-white">
            <router-link to="/clothing">
              <div class="card-body">
                <h1 class="title-blog">Fashion</h1>
                <p class="card-text mt-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <!--<router-link to="/clothing" class="btn btn-outline-light mt-2 mb-4">{{$t("home.button")}}</router-link>-->
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="card bg-black text-white">
            <div class="card-body">
              <h1 class="title-blog">Beauty & Pharma</h1>
              <p class="card-text mt-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <!--<a class="btn btn-outline-light mt-2 mb-4">{{$t("home.button")}}</a>-->
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="card bg-black text-white">
            <div class="card-body">
              <h1 class="title-blog">Food</h1>
              <p class="card-text mt-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <!--<a class="btn btn-outline-light mt-2 mb-4">{{$t("home.button")}}</a>-->
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="card bg-black text-white">
            <div class="card-body">
              <h1 class="title-blog">Accessori</h1>
              <p class="card-text mt-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <!--<a class="btn btn-outline-light mt-2 mb-4">{{$t("home.button")}}</a>-->
            </div>
          </div>
        </div>
        <!--<div class="col-lg-4">
          <div class="cardblock">
            <div class="body">
              <div class="cardmedia">
              <img class="img-fluid" src="/img/partners/teamsystem.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock">
            <div class="body">
              <div class="cardmedia">
                <img class="img-fluid" src="/img/partners/cassaincloud.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock" style="min-height: 200px;">
            <div class="body">
              <div class="cardmedia">
                <img class="img-fluid" src="/img/partners/nabodgital.png" alt="">
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  /* eslint-disable */
  name: "Digital",
  components: {TextScroller, BoxParticles},
  mounted() {

  },
  methods : {

  }
}
</script>

<style scoped>

</style>