<template>
  <section class="wrappagefluid">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <div class="p-30">
            <h1 class="title-section mb-5" data-aos="fade-left">Fashion</h1>
            <p class="text-paragraph">
              Ci impegniamo a guidare la trasformazione digitale delle aziende, adottando tecnologie avanzate per migliorare i processi operativi. Le nostre soluzioni software in cloud innovative permettono alle organizzazioni di ottimizzare l'efficienza, ridurre i costi e potenziare la capacità di adattamento alle sfide del mercato. Offriamo un supporto continuo per integrare le nuove tecnologie.
            </p>
          </div>
        </div>
        <div class="col-lg-6 imagebg" style="background-image: url('/img/ai.jpeg')"></div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row mt-4">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <h1 class="title-section" data-aos="fade-down">Cosa facciamo</h1>
              <p class="text-paragraph text-white">
                Per il settore dell'abbigliamento abbiamo creato una serie di funzionalità che permettono al
                cliente di poter gestire in modo smart ed efficace la sua attività e di poter monitorare i costi.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      Accordion Item #1
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Accordion Item #2
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  /* eslint-disable */
  name: "Clothing",
  components: {TextScroller, BoxParticles},
  mounted() {

  },
  methods : {

  }
}
</script>

<style scoped>

</style>